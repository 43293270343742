import { ReactNode, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { Menu, Tooltip } from 'antd';
import isEmpty from 'lodash/isEmpty';
import iconEdit from 'images/icon-edit.svg';
import styles from 'components/SideNavItem/sideNavItem.module.less';
import { useOverflow } from 'hooks/useOverflow';

type SideNavLabel = {
  label: string;
  icon?: ReactNode;
  isEditMode?: boolean;
  onEditIconClick?: () => void;
};


const SideNavLabel = (props: SideNavLabel) => {
  const { label, icon, isEditMode, onEditIconClick } = props || {};
  const textRef = useRef<HTMLDivElement>(null);
  const isOverflowing = useOverflow(textRef);

  return (
    <div
      className={styles.labelWrapper}>
      {isEditMode ? (
        <img
          className={styles.editIcon}
          src={iconEdit}
          alt="Edit"
          onClick={(e) => {
            e.stopPropagation();
            onEditIconClick?.();
          }}
        />
      ) : null}{' '}
      {icon}{' '}
      {isOverflowing ? (
        <Tooltip 
        title={label} 
        placement="top" 
        overlayClassName={styles.tooltipWrapper}
      >
        <div
            ref={textRef}
            className={styles.textContainer}
          >
            {label}
          </div>
      </Tooltip>
    ) : (
      <div
          ref={textRef}
          className={styles.textContainer}
        >
          {label}
        </div>
    )}
    </div>
  );
};
type SideNavItemProps = {
  path: string;
  label: string;
  icon?: ReactNode;
  previewToken: string | null;
  hash: string;
  employerName?: string;
  isEditMode?: boolean;
  onEditIconClick?: () => void;
};
const SideNavItem = (props: SideNavItemProps) => {
  const {
    path,
    label,
    icon,
    previewToken,
    hash,
    employerName,
    isEditMode,
    onEditIconClick,
    ...rest
  } = props || {};
  const navigate = useNavigate();
  const textRef = useRef<HTMLDivElement>(null);
  const isOverflowing = useOverflow(textRef);

  return (
    <div className={styles.menuLabelWrapper}>
      <Menu.Item
        style={{ paddingLeft: 22, textOverflow: 'ellipsis' }}
        {...rest}
        title={null}
        onClick={() => {
          navigate(
            `${
              isEmpty(path)
                ? `/${hash}${employerName ? '/' + employerName : ''}`
                : path
            }${previewToken ? `?previewToken=${previewToken}` : ''}`
          );
        }}
      >
        <div className={styles.displayContainer}>
          {isEditMode ? (
            <img
              className={styles.editIconSideNav}
              src={iconEdit}
              alt="Edit"
              onClick={(e) => {
                e.stopPropagation();
                onEditIconClick?.();
              }}
            />
          ) : null}
          {icon}
          {isOverflowing ? (
            <Tooltip 
              title={label} 
              placement="top" 
              overlayClassName={styles.tooltipWrapper}
            >
              <div ref={textRef} className={styles.textContainer}>
                {label}
              </div>
            </Tooltip>
          ) : (
            <div ref={textRef} className={styles.textContainer}>
              {label}
            </div>
          )}
        </div>
      </Menu.Item>
    </div>
  );
};
export { SideNavItem, SideNavLabel };
