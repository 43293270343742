import { FC, useState } from 'react';
import isEmpty from 'lodash/isEmpty';

import OnSched from 'components/BenefitsConsultation/components/OnSched';
import ConsultantTypeSelect from 'components/BenefitsConsultation/components/ConsultantTypeSelect/ConsultantTypeSelect';
import {
  BC_STEP_THREE,
  CONSULTANT_TYPE_BROKER,
  CONSULTANT_TYPE_EMPLOYER,
} from 'constants/commonConstants';

import { trackEvents } from 'utils/initGA4';
import styles from './benefitsConsultation.module.less';

type BCProps = {
  setStep: Function;
  locationId: string;
  brokerServiceId: string;
  employerServiceId: string;
  setConsultationType: Function;
  consultationType: string;
  clientId: string;
  employerConsultationEnabled: boolean;
  brokerConsultationEnabled: boolean;
  employerId: string;
};

const BenefitsConsultation: FC<BCProps> = (props: BCProps) => {
  const {
    setStep,
    locationId,
    brokerServiceId,
    employerServiceId,
    clientId,
    setConsultationType,
    consultationType,
    brokerConsultationEnabled,
    employerConsultationEnabled,
    employerId,
  } = props;
  const [selectedServiceId, setSelectedServiceId] = useState<string>('');

  return (
    <div className={styles.benefitsConsultationWrapper}>
      {isEmpty(selectedServiceId) ? (
        <>
          {brokerConsultationEnabled && (
            <ConsultantTypeSelect
              header={'Consultation with a Broker Representative'}
              description="Schedule a consultation with a Broker representative to discuss your complex benefits questions: How to pick a plan, how to file a Qualifying Life Event, and more."
              onSelect={() => {
                setSelectedServiceId(brokerServiceId);
                setConsultationType(CONSULTANT_TYPE_BROKER);
                setStep(BC_STEP_THREE);
                trackEvents({
                  category: 'Benefits Consultation',
                  action: 'schedule_broker_consultation',
                  label: 'Schedule Broker Consultation',
                });
              }}
            />
          )}

          {employerConsultationEnabled && (
            <ConsultantTypeSelect
              header={'Consultation with your Employer'}
              description="Schedule a consultation with an Employer representative to discuss your general benefits questions. "
              onSelect={() => {
                setSelectedServiceId(employerServiceId);
                setConsultationType(CONSULTANT_TYPE_EMPLOYER);
                setStep(BC_STEP_THREE);
                trackEvents({
                  category: 'Benefits Consultation',
                  action: 'schedule_employer_consultation',
                  label: 'Schedule Employer Consultation',
                });
              }}
            />
          )}
        </>
      ) : (
        <OnSched
          locationId={locationId}
          clientId={clientId}
          serviceId={selectedServiceId}
          consultationType={consultationType}
          employerId={employerId}
        />
      )}
    </div>
  );
};

export default BenefitsConsultation;
